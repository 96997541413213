import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { ReactTypeformEmbed } from 'react-typeform-embed';

const IndexPage = () => (
    <Layout>
        <SEO title="Early Access Quiz" />
        
        <div className="ruzu">
            <ReactTypeformEmbed url="https://atridgedcosta.typeform.com/to/v8xE4z" />
        </div>
    </Layout>
)

export default IndexPage
